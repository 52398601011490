import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';

// import LanguageSwitch from 'elements/LanguageSwitch';
import MultiLevelMenu from 'elements/MultiLevelMenu.js';
import DynamicIcon from 'elements/DynamicIcon.js';

import { getWebsite } from 'utils/WebsiteUtils.js';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';

import globalConfig from 'config/globalConfig.js';

export default function MenuSlider(props) {
    let location = useLocation();

    useEffect(() => {
        if (props.onSliderClose())
            props.onSliderClose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <motion.div
            className={'fixed w-full h-full z-50 ' + (props.className || '')}
            initial={props.open ? 'visible' : 'hidden'}
            animate={props.open ? 'visible' : 'hidden'}
            variants={{ visible: { opacity: 1, display: 'flex' }, hidden: { opacity: 0.9999, transitionEnd: { display: 'none' } } }}>

            <motion.div
                className='absolute w-full h-full bg-white bg-opacity-70'
                initial={props.open ? 'visible' : 'hidden'}
                animate={props.open ? 'visible' : 'hidden'}
                variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}
                onClick={() => props.onSliderClose()} />

            <div className='absolute right-0 h-full w-3/4 max-w-sm filter'>

                <motion.div
                    className='bg-oszsz-blue rounded-l-xl shadow-xl h-full flex flex-col gap-6 pt-5 pb-6'
                    initial={props.open ? 'visible' : 'hidden'}
                    animate={props.open ? 'visible' : 'hidden'}
                    style={{ originX: 1, originY: 0.5, transformPerspective: 1250 }}
                    transition={{ duration: 0.35 }}
                    variants={{ visible: { rotateY: 0, display: 'flex' }, hidden: { rotateY: -90, transitionEnd: { display: 'none' } } }}>

                    <div className='relative w-full flex px-6 h-8'>

                        <Link to='/' className='flex-1 flex flex-row gap-3 items-center text-white opacity-50'>
                            <DynamicIcon className='text-2xl' iconName={'FaListUl'} />
                            <div className='self-end text-3xl font-bold tracking-wide leading-none'>MENÜ</div>
                        </Link>

                    </div>

                    <MultiLevelMenu
                        className='w-full flex-1 overflow-y-auto overflow-x-hidden px-6'
                        data={getValueFromParameterMap(getWebsite().globalContent, 'menu')}
                        baseLevel={0}
                        firstLevelKey='itemList'
                        activeClassName='font-semibold'
                        baseClassName='text-white tracking-wide'
                        mainClassName='text-sm uppercase'
                        subClassName='text-xs'
                        orbClassName='bg-oszsz-blue-dark'
                        iconClassName='text-white' />


                    <div className='w-full px-6 flex flex-col gap-6 items-center justify-center text-center text-white'>

                        <div className='flex flex-col items-center'>
                            {/* <img className='h-[30px] mb-2' alt='Creo Group' src='/assets/images/logo/oszsz-logo-white.png' /> */}
                            <div className='uppercase font-bold text-sm'>{globalConfig.etc.titleMain}</div>
                            <div className='text-xs opacity-75'>{globalConfig.etc.titleSub}</div>
                        </div>

                        <div className='flex flex-row items-center gap-2'>
                            {/* <div className='text-xs'>Készítette:</div> */}

                            <a href='https://creo.hu/' className='relative w-[76px] h-[26px]'>
                                <img className='absolute' alt='Creo Group' src='/assets/images/footer/creo_logo_w.png' />
                            </a>
                        </div>

                        <div className='text-xs opacity-75'>{new Date().getFullYear() + ' © Minden jog fenntartva.'}</div>

                    </div>

                </motion.div>

            </div>

        </motion.div >
    );
}