import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import DateDisplay from 'elements/DateDisplay';

import CmsFrontendControl from 'services/CmsFrontendControl.js';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';
import { getWebsite } from 'utils/WebsiteUtils.js';
import globalConfig from 'config/globalConfig';

const listLength = 200;
const listPager = 5;

export default function NewsLister(props) {
    const [visible, setVisible] = useState(props.mainPage ? 1 : listPager);
    const [news, setNews] = useState(null);

    useEffect(() => {
        loadNews();
    }, [])

    const loadNews = (_length) => {
        CmsFrontendControl.getList(getValueFromParameterMap(getWebsite().globalContent, 'news-list').key, 0, listLength).then(result => { setNews(getFilteredList(result.contentList)); });
    }

    const getFilteredList = (list) => {
        let _list = [];
        const key = getValueFromParameterMap(getWebsite().globalContent, 'nev-rovid');
        for (let i = 0; i < list.length; i++) {
            const site = getValueFromParameterMap(list[i], 'site');
            if (site === 'OSZSZ' || site === key)
                _list.push(list[i]);
        }
        return _list;
    };

    const getNews = () => {
        if (!news) return null;
        return news.slice(0, visible);
    };

    const loadModeNews = () => {
        setVisible(Math.min((visible + listPager), news.length));
    };

    // useEffect(() => {
    //     setNews(getValueFromParameterMap(getWebsite().globalContent, 'news-list').contentList.slice(0, listLength))
    // }, [])

    // const loadModeNews = (_length) => {
    //     CmsFrontendControl.getList(getValueFromParameterMap(getWebsite().globalContent, 'news-list').key, news ? (news.length) : 0, _length ? _length : listLength).then(result => {
    //         setNews(news ? news.concat(result.contentList) : result.contentList);
    //     });
    // }

    return (
        <div className={'w-full flex flex-col gap-6 ' + (props.className || '')}>

            {/* {!props.mainPage && <div>
                <h1>{getValueFromParameterMap(getWebsite().globalContent, 'name')}</h1>
                <h1>Híreink</h1>
            </div>} */}

            <div className='w-full flex flex-col'>
                {getNews() && getNews().map((item, index) => (<NewsItem key={index} data={item} mainPage={props.mainPage} />))}
            </div>

            {props.mainPage ? <Link to={globalConfig.etc.newsPath} className='button'>További híreink</Link> : <button className='button' onClick={() => loadModeNews()}>Korábbi híreink megtekintése</button>}

        </div>
    );
}

function NewsItem(props) {
    let _data = {
        creationDate: props.data.publicCreationDate,
        coverImage: getValueFromParameterMap(props.data, 'cover-image') && getValueFromParameterMap(props.data, 'cover-image').downloadUrl,
        heading: getValueFromParameterMap(props.data, 'heading'),
        subHeading: getValueFromParameterMap(props.data, 'sub-heading'),
        lead: getValueFromParameterMap(props.data, 'lead'),
        url: getValueFromParameterMap(props.data, 'url'),
    };

    return (
        <div className={'flex flex-col gap-6 content py-6 last:pb-0 ' + (props.mainPage ? 'border-b last:border-b-0 first:pt-0' : 'border-t')}>

            <div className='flex flex-col gap-3'>

                <DateDisplay className='text-xs text-oszsz-gray' date={new Date(_data.creationDate)} />

                {(_data.heading || _data.coverImage) && <Link to={_data.url} className='group flex flex-col gap-3'>

                    {_data.coverImage && <div className='w-full bg-neutral-200 rounded-lg group-hover:grayscale transition overflow-hidden'><img className='min-w-full' src={_data.coverImage} alt={_data.heading} /></div>}

                    <h1 className='group-hover:text-oszsz-blue transition'>{_data.heading}</h1>

                </Link>}

                {_data.subHeading && <h2>{_data.subHeading}</h2>}

            </div>

            {_data.lead && <section className='' dangerouslySetInnerHTML={{ __html: _data.lead }} />}

        </div>
    );
}