import React, { useState } from 'react';

import GalleryLightbox from 'elements/lightbox/GalleryLightbox.js';

import { getValueFromParameterMap } from 'cms/NBossCMS.js';

export default function Content(props) {
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [galleryIndex, setGalleryIndex] = useState(0);

    const combineGalleryWithImage = (image, gallery) => {
        let _gallery = [];
        // if (image)
        //     _gallery.push(image);
        if (gallery && gallery.contentList)
            _gallery = _gallery.concat(gallery.contentList);
        return _gallery.length > 0 ? _gallery : null;
    };

    let _data = {
        coverImage: getValueFromParameterMap(props.data, 'cover-image') && getValueFromParameterMap(props.data, 'cover-image').downloadUrl,
        heading: getValueFromParameterMap(props.data, 'heading'),
        subHeading: getValueFromParameterMap(props.data, 'sub-heading'),
        lead: getValueFromParameterMap(props.data, 'lead'),
        content: getValueFromParameterMap(props.data, 'content'),
        map: getValueFromParameterMap(props.data, 'map'),
        slider: getValueFromParameterMap(props.data, 'slider'),
        gallery: getValueFromParameterMap(props.data, 'gallery'),
        combinedGallery: combineGalleryWithImage(getValueFromParameterMap(props.data, 'coverImage'), getValueFromParameterMap(props.data, 'gallery')),
    };

    return (
        <div className={'w-full flex flex-col gap-6 ' + (props.className || '')}>

            {_data.coverImage && <div className='w-full bg-neutral-200 rounded-lg group-hover:grayscale transition overflow-hidden'><img className='min-w-full' src={_data.coverImage} alt={_data.heading} /></div>}

            {_data.heading && <h1>{_data.heading}</h1>}

            {_data.subHeading && <h2>{_data.subHeading}</h2>}

            {_data.lead && <section className='lead' dangerouslySetInnerHTML={{ __html: _data.lead }} />}

            {_data.content && <section className='content' dangerouslySetInnerHTML={{ __html: _data.content }} />}

            {_data.gallery && <Gallery data={_data.gallery} onOpen={(index) => { setGalleryIndex(index); setGalleryOpen(!galleryOpen) }} />}

            <GalleryLightbox open={galleryOpen} onClose={() => setGalleryOpen(!galleryOpen)} galleryIndex={galleryIndex} gallery={_data.combinedGallery} />

        </div>
    );
}

function Gallery(props) {
    return (
        <div className='flex flex-col gap-4'>

            <h1>Galéria</h1>

            <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                {props.data.contentList.map((item, index) => (<GalleryItem key={index} data={item} onOpen={() => props.onOpen(index)} />))}
            </div>

        </div>
    );
}

function GalleryItem(props) {
    return (
        <button onClick={props.onOpen} className='aspect-video bg-no-repeat bg-cover rounded-lg shadow-lg overflow-hidden hover:ring-4 ring-oszsz-blue transition' style={{ backgroundImage: 'url(' + props.data.downloadUrl + ')' }}></button>
    );
}