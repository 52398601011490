import { useState } from 'react';
// import { Link } from 'react-router-dom';
import { Spin as Hamburger } from 'hamburger-react'

import Logo from 'elements/Logo.js';
import MenuSlider from 'components/MenuSlider.js';
import Menu from 'components/Menu.js';

export default function Header(props) {
    const [sliderOpen, setSliderOpen] = useState(false);

    return (
        <header className={'w-full flex flex-col items-center bg-white z-50 ' + (props.className || '')}>

            {/* <TopHeader className=' ' /> */}

            <div className='w-full block md:hidden'>
                <MenuSlider className='flex md:hidden' open={sliderOpen} onSliderClose={() => setSliderOpen(false)} />
            </div>

            <div className={'w-full py-4 md:py-6 xl:py-7 -max-w-oszsz-boxed px-6 md:px-0 xl:px-8 flex flex-row md:flex-col xl:flex-row items-center justify-between gap-4 ' + (props.className || '')}>

                <Logo className='flex-shrink-0' />

                <div className={'block md:hidden z-50 ' + (sliderOpen ? 'fixed right-4' : 'absolute right-4')}>
                    <Hamburger className='transition-all' size={25} color={sliderOpen ? '#ffffff' : '#192347'} direction='left' toggled={sliderOpen} toggle={setSliderOpen} />
                </div>

                <Menu className='hidden md:flex' />

            </div>

        </header>
    );
}

// function TopHeader(props) {
//     return (
//         <div className={'w-full h-12 flex items-center justify-center bg-oszsz-gray-light ' + (props.className || '')}>

//             <div className='w-full max-w-oszsz-boxed site-px bg-red-4000'>
//                 <div className='flex flex-row items-center gap-4'>
//                     <div className='text-sm text-oszsz-gray-dark'>Tagszervezeteink</div>
//                     <div className='flex flex-row gap-2'>
//                         <MemberOrganizationItem />
//                         <MemberOrganizationItem />
//                         <MemberOrganizationItem />
//                         <MemberOrganizationItem />
//                     </div>
//                 </div>
//             </div>

//         </div>
//     );
// }

// function MemberOrganizationItem(props) {
//     return (
//         <button>
//             <img className='w-[31px] h-[31px]' alt='logo' src='https://oszsz.hu/backings/partnerControl/getLogo?partnerId=6' />
//         </button>
//     );
// }

// function LogoBar(props) {
//     return (
//         <div className={'w-full pt-8 pb-8 max-w-oszsz-boxed site-px flex items-center justify-centerr ' + (props.className || '')}>

//             <Logo />

//         </div>
//     );
// }

// function MenuBar(props) {
//     return (
//         <div className='w-full h-14 bg-gray-100 border-b-4 border-oszsz-blue'>

//         </div>
//     );
// }