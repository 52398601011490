import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import globalConfig from 'config/globalConfig.js'

export default function CookieConsent(props) {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const cookieConsent = localStorage.getItem(globalConfig.etc.cookiePerfix + 'CookieConsent')
        let showPopup = cookieConsent ? false : true;
        if (showPopup)
            setTimeout(() => setVisible(true), 3000);
    }, [])

    return (
        <motion.div
            className={'fixed bottom-0 left-0 w-full md:w-96 bg-oszsz-blue-dark rounded-t-lg md:rounded-lg shadow-xl md:m-6 p-4 sm:p-6 ' + (props.className || '')}
            initial='hidden'
            animate={visible ? 'visible' : 'hidden'}
            variants={{ 'visible': { opacity: 1, y: 0, display: 'flex' }, 'hidden': { opacity: 0, y: 300, transitionEnd: { display: 'none' } } }} >

            <div className='flex flex-col gap-3 text-white'>

                <div className='font-bold text-lg'>Cookie beállítások</div>
                <div className='text-xs leading-5'>Honlapunk megfelelő működése érdekében Cookie-knak nevezett adatfájlokat (magyarul: sütiket) helyezünk el eszközén, amelyek javítják a felhasználói élményt és biztosítják az oldal megfelelő működését.</div>

                <Link to={globalConfig.etc.cookiePolicyPath} className='font-bold text-sm transition hover:text-oszsz-blue'>További információk</Link>

                <button
                    className='w-full button hover:bg-white hover:text-oszsz-blue-dark'
                    onClick={() => { setVisible(false); localStorage.setItem((globalConfig.etc.cookiePerfix + 'CookieConsent'), Date.now()); }}>
                    Elfogadom
                </button>

            </div>

        </motion.div>
    );
}