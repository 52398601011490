import React from 'react';

import * as FontAwesome from 'react-icons/fa';

export default function DynamicIcon(props) {
    const { iconName } = props;
    const icon = React.createElement(FontAwesome[iconName]);

    return (
        <span className={props.className}>{icon}</span>
    );
}