
import { useState } from 'react';

import Content from 'components/Content.js';
import Contact from 'components/Contact.js';
import NewsLister from 'components/NewsLister.js';
import RightBar from 'components/RightBar.js';
import Slideshow from 'components/Slideshow.js';
import Breadcrumb from 'components/Breadcrumb';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS.js';

export default function SimplePage(props) {
    const [page, setPage] = useState(null);

    return (
        <NBossCMSPage pageprops={props} pageDataReady={page => { setPage(page) }} className={'w-full flex-1 flex flex-col items-start transition'}>

            {(page && page.pageTemplate.key === 'main-page' && getValueFromParameterMap(page, 'slider')) && <Slideshow className='order-1 z-0' data={getValueFromParameterMap(page, 'slider')} />}

            <div className='w-full flex-1 flex flex-col lg:flex-row order-2'>

                <div className='flex-1 flex flex-col pr-0 lg:pr-16 pt-6 pb-6 sm:pb-16 gap-4'>
                    {(page && page.pageTemplate.key !== 'main-page') && <Breadcrumb />}
                    {(page && page.parameterMap['content']) && <Content data={page.parameterMap['content'].value} />}
                    {(page && (page.pageTemplate.key === 'main-page' || page.pageTemplate.key === 'news-list')) && <NewsLister mainPage={page.pageTemplate.key === 'main-page'} />}
                    {(page && page.pageTemplate.key === 'contact-page') && <Contact />}
                </div>

                <div className='flex sm:hidden lg:flex flex-col items-center lg:items-start lg:bg-gradient-to-r from-oszsz-gray-light w-full lg:w-1/3 lg:max-w-[427px]'>
                    <RightBar className='sticky top-0 bottom-0 lg:p-6 lg:pr-0 pt-0 py-6' />
                </div>

            </div>

        </NBossCMSPage>
    );
}