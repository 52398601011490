import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { getValueFromParameterMap } from 'cms/NBossCMS.js';

const duration = 3;
// const transitionDuration = 0.6;

export default function Slideshow(props) {
    const [activeIndex, setActiveIndex] = useState(0);

    let getNext = () => { return activeIndex + 1 >= getValueFromParameterMap(props.data, 'slide-list').contentList.length ? 0 : activeIndex + 1; }
    // let getPrev = () => { return activeIndex - 1 < 0 ? getValueFromParameterMap(props.data, 'slide-list').contentList.length - 1 : activeIndex - 1; }

    useEffect(() => {
        if (props.data != null)
            setActiveIndex(0);
    }, [props.data])

    useEffect(() => {
        // console.log('activeIndex ' + activeIndex);
        let timer = setTimeout(() => {
            setActiveIndex(getNext());
        }, duration * 1000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex])

    return (
        <div className={'bg-oszsz-gray-light w-full rounded-b-lg shadow-xl overflow-hidden ' + (props.className || '')}>

            <div className='aspect-[1379/428] bg-oszsz-gray-light overflow-hidden relative' >

                {getValueFromParameterMap(props.data, 'slide-list').contentList.map((item, index) => (<SlideImage key={index} data={item} active={activeIndex === index} />))}

            </div>

            {(getValueFromParameterMap(props.data, 'slide-list') && getValueFromParameterMap(props.data, 'slide-list').contentList.length > 1) && <div className='h-16 bg-oszsz-blue flex items-center justify-between'>

                <div className='relative flex-1 h-full'>

                    {getValueFromParameterMap(props.data, 'slide-list').contentList.map((item, index) => (<SlideText key={index} data={item} active={activeIndex === index} />))}

                </div>

                <div className='h-full p-2 flex flex-row gap-2'>

                    {getValueFromParameterMap(props.data, 'slide-list').contentList.map((item, index) => (

                        <button className='aspect-square bg-oszsz-blue-dark hover:bg-white rounded-md text-white hover:text-oszsz-blue-dark text-sm font-bold flex items-center justify-center transition'
                            onClick={() => setActiveIndex(index)}>
                            {index + 1}
                        </button>

                    ))}

                </div>

            </div>}

        </div>
    );
}

// function SlideButton(props) {
//     return (

//     );
// }


function SlideImage(props) {
    return (
        <motion.div
            className='absolute w-full h-full bg-center bg-no-repeat bg-cover'
            style={{ backgroundImage: 'url(' + props.data.parameterMap['image'].value.downloadUrl + ')' }}
            initial={props.active ? 'visible' : 'hidden'}
            animate={props.active ? 'visible' : 'hidden'}
            variants={{ 'visible': { opacity: 1, scale: 1 }, 'hidden': { opacity: 0, scale: 1 } }}>

        </motion.div>
    );
}

function SlideText(props) {
    return (
        <motion.div
            className='absolute top-0 left-0 w-full h-full flex items-center px-6 text-white text-xs sm:text-sm'
            initial={props.active ? 'visible' : 'hidden'}
            animate={props.active ? 'visible' : 'hidden'}
            variants={{ 'visible': { opacity: 1, scale: 1 }, 'hidden': { opacity: 0, scale: 1 } }}>{console.log(props.data)}{getValueFromParameterMap(props.data, 'image-description')}</motion.div>
    );
}