import DynamicIcon from 'elements/DynamicIcon.js';

export default function LightboxLoader() {
    return (
        <div className='w-full h-full flex items-center justify-center'>
            <div className='flex flex-col items-center justify-center opacity-20 gap-3'>
                {/* <img className='w-20 h-20' alt='logo' src='/assets/images/logo/shield-outline-white.svg' /> */}
                <DynamicIcon className='text-4xl text-white animate-spin ' iconName={'FaHourglassHalf'} />
                <p className='text-sm font-bold text-white tracking-wider uppercase'>Betöltés...</p>
            </div>
        </div>
    )
}