const globalConfig = {
    'etc': {
        'cookiePerfix': '_oszsz',
        'privacyPolicyPath': '/adatkezeles',
        'cookiePolicyPath': '/cookie-tajekoztato',
        'newsPath': '/hirek',
        'contactPath': '/kapcsolat'
    },
}

export default globalConfig;