import DynamicIcon from 'elements/DynamicIcon.js';

import { getDateString } from 'utils/GenericUtils';

export default function DateDisplay(props) {
    return (
        <div className={'flex flex-row items-center gap-1.5 leading-4 sm:leading-6 ' + (props.className || '')}>
            <DynamicIcon className='' iconName={'FaCalendar'} />
            <div className='leading-none'>{getDateString(props.date)}</div>
        </div>
    );
}