import nBossConnection from 'services/nBossConnection.js';

class ContactUsControl {
    constructor() {
        this.connection = nBossConnection.connection;
        this.CONTROL = 'contactUsControl';
    }

    /**
     * @param {dto} dto
     */
    send(dto) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/send`, { dto }).then(response => resolve(response.data)).catch(reject);
        });
    }

}

export default new ContactUsControl();
