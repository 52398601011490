import React, { useState } from 'react';

import Logo from 'elements/Logo.js';
import DynamicIcon from 'elements/DynamicIcon.js';

import { getWebsite } from 'utils/WebsiteUtils.js';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';

import ContactUsControl from 'services/ContactUsControl.js';

export default function Contact(props) {
    return (
        <div className='flex flex-col gap-4'>

            <ContactData />
            {/* <ContactForm /> */}

        </div>
    )
}

function ContactData(props) {
    let _data = {
        email: getValueFromParameterMap(getWebsite().globalContent, 'email'),
        phone: getValueFromParameterMap(getWebsite().globalContent, 'phone'),
        mobile: getValueFromParameterMap(getWebsite().globalContent, 'mobile')
    };

    return (
        <div className='bg-white rounded-lg border p-6 flex flex-col sm:flex-row items-center gap-6 justify-between'>

            <Logo alternate />

            <div className='flex flex-row items-center gap-8 bg-red-4000'>
                {_data.email && <ContactBlockLine title='E-mail' icon='FaEnvelope' value={_data.email} href={'mailto:' + _data.email} />}
                {_data.phone && <ContactBlockLine title='Telefon' icon='FaPhoneAlt' value={_data.phone} href={'tel:' + _data.phone} />}
                {_data.mobile && <ContactBlockLine title='Mobil' icon='FaMobileAlt' value={_data.mobile} href={'tel:' + _data.mobile} />}
            </div>


        </div>
    );
}

function ContactBlockLine(props) {
    return (
        <div className='flex flex-col items-center gap-2 text-xs'>

            <div className='flex flex-row items-center gap-2 text-oszsz-blue-dark'>
                <DynamicIcon className='' iconName={props.icon} />
                <div className='uppercase font-bold'>{props.title}</div>
            </div>

            <a href={props.href} className='hover:text-oszsz-blue transition text-xxs xl:text-sm'>{props.value}</a>

        </div>
    );
}

function ContactForm(props) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [feedbackVisible, setFeedbackVisible] = useState(false);
    const [feedbackSuccessful, setFeedbackSuccessful] = useState(false);

    const inputClassName = 'w-full border outline-none placeholder-oszsz-gray rounded-lg px-4 py-3';

    const sendMessage = () => {
        console.log('sendMessage')
        ContactUsControl.send({ name, email, message }).then(result => { setFeedbackVisible(true); setFeedbackSuccessful(result.successful); });
    }

    return (
        <div className='flex flex-col gap-3'>

            <div className='flex flex-col lg:flex-row gap-3'>
                <input className={inputClassName} type='text' name='name' placeholder={'Név *'} value={name} onChange={e => setName(e.target.value)} />
                <input className={inputClassName} type='text' name='email' placeholder={'E-mail cím *'} value={email} onChange={e => setEmail(e.target.value)} />
            </div>

            <textarea placeholder={'Üzenet tartalma'} className={inputClassName + ' h-64 resize-none'} value={message} maxLength={1024 * 10} onChange={e => setMessage(e.target.value)} />

            <button to='/' className='button' onClick={() => sendMessage()}>Üzenet küldése</button>

            {feedbackVisible && <div className={'rounded-lg p-4 text-white text-sm text-center ' + (feedbackSuccessful ? 'bg-green-700' : 'bg-red-600')}>
                {feedbackSuccessful && <div><strong>Sikeres üzenetküldés!</strong><br /><span className='text-xs'>Kollégánk hamarosan felveszi Önnel a kapcsolatot.</span></div>}
                {!feedbackSuccessful && <div><strong>Sikertelen üzenetküldés!</strong><br /><span className='text-xs'>Kérjük, ellenőrizze a megadott adatokat.</span></div>}
            </div>}

        </div>
    );
}