import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { motion } from 'framer-motion';

import Header from 'components/Header.js';
import Footer from 'components/Footer.js';
import SimplePage from 'pages/SimplePage.js';
import Error404Page from 'pages/Error404Page.js';
import CookieConsent from 'elements/CookieConsent';
// import ModalImportantMessage from 'elements/modal/ModalImportantMessage';

import DynamicIcon from 'elements/DynamicIcon.js';

import CmsFrontendControl from 'services/CmsFrontendControl.js';
import { getWebsite, setWebsite } from 'utils/WebsiteUtils.js';
import ScrollToTop from 'utils/ScrollToTop';

export default function NBossCMSClient(props) {
    const [website, _setWebsite] = useState(null);
    const [routes, setRoutes] = useState(null);

    const pageDictionary = {
        'main-page': { page: SimplePage, templateProps: { menu: true } },
        'content-page': { page: SimplePage, templateProps: { menu: true } },
        'egyszeru-oldal': { page: SimplePage, templateProps: { menu: true } },
        'contact-page': { page: SimplePage, templateProps: { menu: true } },
        'news-list': { page: SimplePage, templateProps: { menu: true } },
        '404': { page: Error404Page },
    };

    const createRoutes = (website) => {
        let _routes = [];

        if (website.mainPage)
            _routes.push({ path: '/', component: pageDictionary[website.mainPage.pageTemplate].page, pageKey: website.mainPage.pageKey });

        for (let key in website.sitemap)
            if (website.sitemap[key].pageTemplate && pageDictionary[website.sitemap[key].pageTemplate])
                _routes.push({ path: key, component: pageDictionary[website.sitemap[key].pageTemplate].page, pageKey: website.sitemap[key].pageKey, templateProps: pageDictionary[website.sitemap[key].pageTemplate].templateProps });
            else
                console.log('[Missing template] ' + (website.sitemap[key].pageTemplate ? website.sitemap[key].pageTemplate : 'No template defined.') + ' [for] ' + key);

        _routes.push({ path: '*', component: pageDictionary['404'].page, pageKey: 'egyszeru-oldal' });

        return _routes;
    }

    useEffect(() => {
        CmsFrontendControl.getWebsite(props.domainName, window.location.pathname).then(result => { _setWebsite(result) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (website !== null) {
            setWebsite(website);
            setRoutes(createRoutes(website))
            setSiteProperties();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [website]);

    const setSiteProperties = () => {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = getValueFromParameterMap(website.globalContent, 'logo').downloadUrl;

        document.title = getValueFromParameterMap(website.globalContent, 'name');
    }

    if (website && routes) {
        return (
            <Router>

                <div className='relative bg-white min-h-screen w-full z-10 shadow-xl flex flex-col items-center border-b-4 border-oszsz-blue bg-gradient-to-r'>

                    <Header className='order-1' />

                    <div className={'w-full flex-1 flex justify-center order-2 bg-gradient-to-b from-oszsz-gray-light via-white to-white'}>

                        <div className='w-full flex max-w-oszsz-boxed site-px'>
                            <Switch>{routes.map((route, index) => (<Route key={index} exact path={route.path} render={props => (<route.component {...props} pageKey={route.pageKey} templateProps={route.templateProps || {}} />)} />))}</Switch>
                            {/* <ScrollToTop /> */}
                        </div>

                    </div>

                </div>

                <Footer className='w-full sticky top-0 bottom-0 left-0' />
                <CookieConsent className='z-50' />
                {/* <ModalImportantMessage className='z-50' data={getValueFromParameterMap(website.globalContent, 'popup')} /> */}



            </Router >
        );
    } else {
        return (
            <div className='w-full min-h-screen flex flex-col justify-center items-center gap-4'>
                <DynamicIcon className='text-4xl text-oszsz-blue animate-spin ' iconName={'FaHourglassHalf'} />
                <p className='text-sm font-extrabold text-oszsz-blue-dark uppercase'>Betöltés...</p>
            </div>
        );
    }
}

export function NBossCMSPage(props) {
    useEffect(() => {
        if (checkPageLanguage(getWebsite().sitemap, window.location.pathname, getWebsite().currentLocale.key))
            CmsFrontendControl.getPage(props.pageprops.pageKey).then(result => { props.pageDataReady ? props.pageDataReady(result) : console.log('No pageDataReady callback defined.') });
        else
            // console.log('reload');
            window.location.reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkPageLanguage = (sitemap, path, localeKey) => {
        const hasSitemapItem = sitemap[path] ? true : false;
        // console.log('hasSitemapItem: ' + hasSitemapItem);
        const hasLocale = hasSitemapItem && (sitemap[path] && sitemap[path].localeList.indexOf(localeKey) > -1);
        // console.log('hasLocale: ' + hasLocale);
        const isMainPage = path === '/' || path === '';
        // console.log('isMainPage: ' + isMainPage);

        return (hasLocale || isMainPage || !hasSitemapItem) ? true : false;
    }

    return (<motion.div
        className={props.className}
        // style={{ originX: 0.5, originY: 0, transformPerspective: 2000 }}
        transition={{ duration: .3 }}
        initial={{ opacity: 0/*, rotateX: 0*/ }}
        animate={{ opacity: 1/*, rotateX: 0*/ }}
        exit={{ opacity: 1/*, rotateX: 0*/ }}>
        {props.children}
    </motion.div>);
}

export const getParameterEndMatch = (data, valueKey, end) => {
    if (data && valueKey && end)
        for (let key in data.parameterMap)
            if (key.endsWith(end))
                return data.parameterMap[key][valueKey];
}

export const getValueFromParameterMap = (data, key) => {
    if (data && key && data.parameterMap[key])
        return data.parameterMap[key].value;
    return null;
};

export const getNBossEndpoint = () => {
    return `http${process.env.REACT_APP_API_SECURE === 'true' ? `s` : ``}://${process.env.REACT_APP_API_HOST + (typeof process.env.REACT_APP_API_PORT !== 'undefined' ? `:${process.env.REACT_APP_API_PORT}` : ``)}/${process.env.REACT_APP_API_NAME}/methods/`;
}