import { Link } from 'react-router-dom';

import { getWebsite } from 'utils/WebsiteUtils.js';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';

export default function Logo(props) {
    const fontSizeTopShort = 'text-lg sm:text-2xl md:text-3xl lg:text-4xl';
    const fontSizeTopLong = 'text-[13px] sm:text-2xl xl:text-[22px] 2xl:text-[28px]';

    const fontSizeBtmShort = 'text-xxs sm:text-sm md:text-lg lg:text-xl';
    const fontSizeBtmLong = 'text-xxs sm:text-sm md:text-base';

    const fontSizeBtmXLong = 'text-[7px] sm:text-sm md:text-base';

    const firstPart = getValueFromParameterMap(getWebsite().globalContent, 'name-first-part');
    const secondPart = getValueFromParameterMap(getWebsite().globalContent, 'name-second-part');

    const gapShort = 'gap-1 sm:gap-0 lg:gap-1';
    const gapLong = 'gap-1 sm:gap-0 lg:gap-0 2xl:gap-1'

    if (props.alternate)
        return (
            <Link to='/' className='flex flex-row items-center gap-5'>

                <img className='h-12' alt='logo' src={getValueFromParameterMap(getWebsite().globalContent, 'logo').downloadUrl} />

                <div className='flex flex-col items-start gap-2'>
                    <div className={'font-bold uppercase text-2xl leading-none ' + (secondPart.length > 16 ? ' text-lg' : ' text-2xl')}>{firstPart}</div>
                    <div className={'tracking-[.15em] leading-none opacity-75 ' + (secondPart.length > 40 ? 'text-xs' : 'text-sm')}>{secondPart}</div>
                </div>

            </Link>
        );
    else
        return (
            <Link to='/' className={'flex flex-row items-center gap-3 sm:gap-5 md:gap-6 ' + (props.className || '')}>

                {/* {console.log('fl ' + firstPart.length)}
                {console.log('sl ' + secondPart.length)} */}

                <img className={'w-[30px] sm:w-[40px] md:w-[50px] 2xl:w-[60px] ' + (getValueFromParameterMap(getWebsite().globalContent, 'nev-rovid') === 'OSZSZ' ? 'mt-1' : 'mt-0')} alt='logo' src={getValueFromParameterMap(getWebsite().globalContent, 'logo').downloadUrl} />

                <div className={'flex flex-col items-start text-oszsz-blue-dark ' + (firstPart.length < 16 ? gapShort : gapLong)}>
                    <div className={'font-bold uppercase leading-none ' + (firstPart.length < 16 ? fontSizeTopShort : fontSizeTopLong)}>{firstPart}</div>
                    <div className={'tracking-[.175em] leading-none ' + (firstPart.length < 16 ? fontSizeBtmShort : (secondPart.length > 40 ? fontSizeBtmXLong : fontSizeBtmLong))}>{secondPart}</div>
                </div>

            </Link>
        );
}