import { useEffect } from 'react';

import NBossCMSClient from 'cms/NBossCMS.js';

import nBossConnection from 'services/nBossConnection.js';

import 'react-image-lightbox/style.css';

function App() {
  useEffect(() => { nBossConnection.setErrorHandler(error => { alert(error); }); });

  return (<NBossCMSClient domainName={process.env.REACT_APP_CMS_DOMAIN_NAME} />);
}

export default App;