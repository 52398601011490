import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getWebsite } from 'utils/WebsiteUtils.js';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';

const getBreadCurmbItems = (location) => {
    let items = [{ 'label': getValueFromParameterMap(getWebsite().globalContent, 'name'), 'to': '/' }];
    let menu = getValueFromParameterMap(getWebsite().globalContent, 'menu').itemList;

    const pathParts = location.pathname.split('/').filter(item => item !== '');

    let pathIndex = 0;
    let menuItem = menu;

    while (pathIndex < pathParts.length && menuItem) {
        let hasItem = false;
        for (let i = 0; i < menuItem.length; i++) {
            if (menuItem[i].path === pathParts[pathIndex]) {
                items.push({ 'label': menuItem[i].title, 'to': menuItem[i].fullPath });
                hasItem = true;
                pathIndex++;
                if (menuItem[i].childList)
                    menuItem = menuItem[i].childList;
            }
        }
        if (!hasItem)
            pathIndex = pathParts.length;
    }

    return items;
}

export default function Breadcrumb(props) {
    const [items, setItems] = useState([]);

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        setItems(getBreadCurmbItems(location));
    }, [location])

    return (
        <div className='w-full text-xxs sm:text-xs leading-4 sm:leading-6'>
            {items.map((item, index) => (
                <div className='inline' key={item.to}>
                    {/* <> */}
                    {index > 0 && <span className='opacity-75'> / </span>}
                    <span key={index} className='opacity-75 hover:opacity-100 transition cursor-pointer' to={item.to} onClick={() => history.push(item.to)}>{item.label}</span>
                    {/* </> */}
                </div>
            ))}
        </div >
    )
}

